import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import './scss/main.scss';
import './plugins/axios'
import vuetify from './plugins/vuetify'
import store from './data/store'
import router from './router'
import material from 'vue-material'
import { formatterFilter, addTime } from '../src/utils/luxon-formater';
import './plugins/vee-validate';


Vue.filter('dateformat', formatterFilter);
Vue.filter('addTime', addTime);
Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  material,
  i18n,
  render: h => h(App)
}).$mount('#app')
