const SessionObject = require('./SessionObject')
const TrustedHubs = require('./TrustedHubs')
const UserObject = require('./UserObject')
module.exports = class Authentication {
    constructor(object) {
        this.sessionObject = new SessionObject(object.sessionObject);
        this.systemEnvironmentName = object.systemEnvironmentName;
        this.trustedHubs = new Array(new TrustedHubs(object.trustedHubs));
        this.userObject = new UserObject(object.userObject);
    }
};