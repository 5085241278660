module.exports = class Notification {
    constructor(object) {
        this.AdditionalData = object.additionalData;
        this.DeviceType = object.deviceType;
        this.FromUserId = object.fromUserId;
        this.MessageToSend = object.notificationMsg;
        this.NotificationTimestamp = object.notificationTimestamp;
        this.NotificationTypeId = object.notificationTypeId;
        this.ReferenceData = object.referenceData;
        this.ToUserId = object.userId;
        this.IsGroupNotification = object.isGroupNotification;
        this.NotificationId = object.notificationId;
        this.IsRead = object.isRead;
        this.fromUserFullname = object.fromUserFullname;
        this.appointmentRequestId = object.appointmentRequestId
    }
};