import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
export default {
    async getAppointments() {
        const response = await _axios.get('appointments')
        if (response.data.resFlag) {
            return response.data
        }
        throw new UsersError('No meta data!')
    },
    async createAppointment(body) {
        const response = await _axios.post('appointments', body)
        if (response.data.resFlag) {
            return response.data
        }
        throw new UsersError('No meta data!')
    },
    async addAppointmentNote(body) {
        const response = await _axios.post('appointments/addnote', body)
        if (response.data.resFlag) {
            return response.data
        }
        throw new UsersError('No meta data!')
    },
    async getAllNotes() {
        const response = await _axios.get('appointments/getnotes')
        if (response.data.resFlag) {
            return response.data
        }
        throw new UsersError('No meta data!')
    },
}