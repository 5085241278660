module.exports = class SessionObject {
    constructor(object) {
        this.expiresAt = object.expiresAt;
        this.sessionGuid = object.sessionGuid;
        this.sessionType = object.sessionType;
        this.userId = object.userId;
        this.id = object.id;
        this.ip = object.ip;
        this.username = object.username;
        this.webSocketSession = object.webSocketSession;
    }
};