import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
export default {
    async createMedicalDevice(body){
        const response = await _axios.post('medicaldevice', body)
        if (response.data.resFlag) {
          const userJson = response.data.data
          return userJson
        } else {
          throw new UsersError('Invalid id')
        }
    },
    async editMedicalDevice(body){
        const response = await _axios.put(`medicaldevice/${body.id}`, body)
        if (response.data.resFlag) {
          const userJson = response.data.data
          return userJson
        } else {
          throw new UsersError('Invalid id')
        }
    },
    async deleteMedicalDevice(body){
        const response = await _axios.delete(`medicaldevice/${body.id}`)
        if (response.data.resFlag) {
          const userJson = response.data.data
          return userJson
        } else {
          throw new UsersError('Invalid id')
        }
    },
    async getMyMedicalDevices(id) {
      const response = await _axios.get('medicaldevice/getmedicaldevicesforpatient', { headers: { 'patientId': id }})
      if (response.data.resFlag) {
        return response.data.data
      }
      throw new UsersError('No meta data!')
    },
    async getDeviceById(id) {
      const response = await _axios.get(`medicaldevice/${id}`)
      if (response.data.resFlag) {
        return response.data.data
      }
      throw new UsersError('No meta data!')
    },
    async getMedicalDeviceTemplate() {
      const response = await _axios.get('medicaldevicetemplate')
      if (response.data.resFlag) {
        return response.data.data
      }
      throw new UsersError('No meta data!')
    },
    async editMedicalDeviceTemplate(){
      // const response = await _axios.put(`medicaldevicetemplate/${body.id}`, body)
      // if (response.data.resFlag) {
      //   const userJson = response.data.data
      //   return userJson
      // } else {
      //   throw new UsersError('Invalid id')
      // }
    },
    async getmedicaldevicebydeviceidentifier (id) {
      const response =  await _axios.get('medicaldevice/getmedicaldevicebydeviceidentifier',  { headers: { 'deviceIdentifier': id }}) // 'E3:F0:01:00:18:72'

      if (response.data.resFlag) {
        return response.data.data
      }
      throw new UsersError('No meta data!')
    }
}