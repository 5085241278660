module.exports = class Message {
    constructor(object) {
        this.AdditionalData = object.additionalData;
        this.DeviceType = object.deviceType;
        this.MessageToSend = object.notificationMsg;
        this.NotificationTypeId = object.notificationTypeId;
        this.ReferenceData = object.referenceData;
        this.ToUserId = object.userId;
        this.IsRead = false;
        this.NotificationTimestamp = new Date().toISOString();
    }
};