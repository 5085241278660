/* eslint-disable no-unused-vars */
import Repository from '../../repositories/RepositoryFactory'

const libphonenumber = require('libphonenumber-js');
const HospitalsRepository = Repository.get('hospitals')

export default {
    namespaced: true,
    state: {
        hospitals: null,
        detailedHospital: null,
        assignedAdmins: null,
        users: null,
        patients: null,
        medicalStaff: null,
        relatedPatients: null,
        hospitalData: null,
    },
    mutations: {
        GET_HOSPITALS(state, payload) {
            if (payload.data !== null) {
                payload.data.forEach(c => {
                    if (c.contactPhone.includes('+')) {
                        c.contactPhone = libphonenumber.parsePhoneNumberFromString(c.contactPhone).formatInternational();
                    }
                })
            }
            state.hospitals = payload.data
        },
        GET_HOSPITAL_BY_ID(state, payload) {
            state.detailedHospital = payload
        },
        GET_RELATED_USERS_FOR_HOSPITAL(state, payload) {
            state.assignedAdmins = payload.data.usersRelatedToHospital?.filter(users => users.roleId === 6)
            state.users = payload.data.usersRelatedToHospital
            state.patients = payload.data.usersRelatedToHospital?.filter(users => users.roleId === 5)
        },
        GET_RELATED_MEDICAL_STAFF(state, payload) {
            state.hospitalData = payload.data.hospitalData
            state.medicalStaff = payload.data.usersRelatedToHospital
        },
        GET_RELATED_PATIENTS(state, payload) {
            state.relatedPatients = payload.data.usersRelatedToHospital
        }
    },
    actions: {
        async getHospitals({ commit }) {
            commit('GET_HOSPITALS', await HospitalsRepository.getHospitals())
        },
        async getHospitalById({ commit }, id) {
            commit('GET_HOSPITAL_BY_ID', await HospitalsRepository.getHospitalById(id))
        },
        // eslint-disable-next-line no-unused-vars
        async createHospital({ commit }, body) {
            const res = await HospitalsRepository.createHospital(body)
            return res
        },
        async editHospital({ commit }, body) {
            const res = await HospitalsRepository.editHospital(body)
            return res
        },
        async deleteHospital({ commit }, id) {
            const res = await HospitalsRepository.deleteHospital(id)
            return res
        },
        async assignAdminToHospital({ commit }, body) {
            const res = await HospitalsRepository.assignAdminToHospital(body)
            return res
        },
        async deassignAdminToHospital({ commit }, body) {
            const res = await HospitalsRepository.deassignAdminToHospital(body)
            return res
        },
        async assignPatientToHospital({ commit }, body) {
            const res = await HospitalsRepository.assignPatientToHospital(body)
            return res
        },
        async assignDoctorToHospital({ commit }, body) {
            const res = await HospitalsRepository.assignDoctorToHospital(body)
            return res
        },
        async getRelatedUsersForHospital({ commit }, hospitalId) {
            commit('GET_RELATED_USERS_FOR_HOSPITAL', await HospitalsRepository.getRelatedUsersForHospital(hospitalId))
        },
        async getRelatedPatients({ commit }, hospitalId) {
            commit('GET_RELATED_PATIENTS', await HospitalsRepository.getRelatedPatients(hospitalId))
        },
        async getRelatedMedicalStaff({ commit }, hospitalId) {
            commit('GET_RELATED_MEDICAL_STAFF', await HospitalsRepository.getRelatedMedicalStaff(hospitalId))
        }
    },
    getters: {},
}