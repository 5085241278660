import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
export default {
    async createTreatmentPlan(body) {
        const response = await _axios.post('treatmentplan', body)
        if (response.data.resFlag) {
            return response.data.data
        }
        throw new UsersError('No meta data!')
    },
    async getTreatmentPlansForPatient(id) {
        const response = await _axios.get('treatmentplan/gettreatmentplansforpatient', { headers: { 'patientId': id } })
        if (response.data.resFlag) {
            return response.data.data
        }
        throw new UsersError('No meta data!')
    },
    async assignDevice(body) {
        const response = await _axios.post('treatmentplan/assigndevice', body)
        if (response.data.resFlag) {
            return response.data.data
        }
        throw new UsersError('No meta data!')
    },
    async deassignDevice(id) {
        const response = await _axios.delete('treatmentplan/deassigndevice', { headers: { 'treatmentPlanDeviceId': id } })
        if (response.data.resFlag) {
            return response.data.data
        }
        throw new UsersError('No meta data!')
    },
}