module.exports = class NotificationsWS {
    constructor(object) {
        this.AdditionalData = object.AdditionalData;
        this.DeviceType = object.DeviceType;
        this.FromUserId = object.FromUserId;
        this.MessageToSend = object.MessageToSend;
        this.NotificationTimestamp = object.NotificationTimestamp;
        this.NotificationTypeId = object.NotificationTypeId;
        this.ReferenceData = object.ReferenceData;
        this.ToUserId = object.UserId;
        this.IsGroupNotification = object.IsGroupNotification;
        this.NotificationId = object.NotificationId;
        this.IsRead = object.IsRead;
        this.fromUserFullname = object.FromUserFullname
        this.MedicalTeamData = object.MedicalTeamData;
        this.MedicalTeamId = object.MedicalTeamId;
        this.NId = object.NId;
        this.NotificationId = object.NotificationId;
        this.NotificationSentToGroup = object.NotificationSentToGroup;
        this.ProcessedBy = object.ProcessedBy;
        this.ProcessedTimestamp = object.ProcessedTimestamp;
        this.ProcessingStatus = object.ProcessingStatus;
        this.RelatedToNotificationId = object.RelatedToNotificationId;
        this.ResolutionComments = object.ResolutionComments;
        this.UserFullname = object.UserFullname;
        this.appointmentRequestId = object.AppointmentRequestId
    }
};