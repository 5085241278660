import Repository from '../../repositories/RepositoryFactory'
import { setAuthToken } from '@/plugins/axios'

const libphonenumber = require('libphonenumber-js');
const AuthenticationRepository = Repository.get('authentication')

export default {
  namespaced: true,
  state: {
    sessionToken: null,
    roleName: null,
    userData: null,
    branchOfficeData: null,
    hospitalId: null,
    myMedicalTeams: []
  },
  mutations: {
    DO_LOGIN(state, payload) {
      state.sessionToken = payload.sessionObject.sessionGuid
      if (payload.userObject.phone.includes('+')) {
        payload.userObject.phone = libphonenumber.parsePhoneNumberFromString(payload.userObject.phone).formatInternational();
      }
      state.userData = payload.userObject
      state.hospitalId = payload.userObject?.hospitalAssignedData?.id
      localStorage.sessionToken = payload.sessionObject.sessionGuid
      setAuthToken(payload.sessionObject.sessionGuid)
      state.roleName = payload.userObject.roleData.roleName
      payload.userObject.medicalTeamsData.forEach(mt => state.myMedicalTeams.push(mt.id))
    },
    LOGOUT(state) {
      localStorage.clear()
      state.sessionToken = null
      state.myMedicalTeams = []
      setAuthToken(null)
    },
    initialize(state) {
      // TODO: In the future, we can load all stored state from
      //       localStorage with https://www.mikestreety.co.uk/blog/vue-js-using-localstorage-with-the-vuex-store

      state.sessionToken = localStorage.sessionToken
      setAuthToken(state.sessionToken)
    },
  },
  actions: {
    async doLogin({ commit }, data) {
      commit('DO_LOGIN', await AuthenticationRepository.login(data))
    },
    async doLoginWithToken({ commit }) {
      commit('DO_LOGIN', await AuthenticationRepository.signinwithtoken())
    },
    async doLogout({ commit }) {
      commit('LOGOUT', await AuthenticationRepository.logout())
    },
  },
  getters: {
    hasStoredSession(state) {
      return (!!state.sessionToken)
    },
    getRole(state) {
      return state.roleName
    },
    getHospitalId(state) {
      return state.hospitalId
    },
    getMyMedicalTeam(state) {
      return state.myMedicalTeams
    },
  },
}
