import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
export default {
  async getHospitals() {
    const response = await _axios.get('hospital')
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No meta data!')
  },
  async getHospitalById(id) {
    const response = await _axios.get(`/hospital/${id}`)
    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new UsersError('Invalid id')
    }
  },
  async createHospital(body) {
    const response = await _axios.post('hospital', body)
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No meta data!')
  },
  async editHospital(body) {
    const response = await _axios.put(`hospital/${body.id}`, body)
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new UsersError('Invalid id')
    }
  },
  async deleteHospital(id) {
    const response = await _axios.delete(`hospital/${id}`)
    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new UsersError('Invalid id')
    }
  },
  async assignAdminToHospital(body) {
    const response = await _axios.post('hospital/assignadmintohospital', body)
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No meta data!')
  },
  async deassignAdminToHospital(body) {
    const response = await _axios.post('hospital/deassignadminfromhospital', body)
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No meta data!')
  },
  async assignPatientToHospital(body) {
    const response = await _axios.post('hospital/assignpatienttohospital', body)
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No meta data!')
  },
  async assignDoctorToHospital(body) {
    const response = await _axios.post('hospital/assigndoctortohospital', body)
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No meta data!')
  },
  async getRelatedUsersForHospital(hospitalId) {
    const response = await _axios.get('hospital/getrelatedusersforhospital', { headers: { 'hospitalId': hospitalId } })
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No meta data!')
  },
  async getRelatedPatients(hospitalId) {
    const response = await _axios.get('hospital/getrelatedpatientsforhospital', { headers: { 'hospitalId': hospitalId } })
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No meta data!')
  },
  async getRelatedMedicalStaff(hospitalId) {
    const response = await _axios.get('hospital/getrelatedmedicalstaffforhospital', { headers: { 'hospitalId': hospitalId } })
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No meta data!')
  },
}