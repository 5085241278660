import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
import NurseMetaData from "../../models/NurseMetaData";
export default {
    async getMetaData(id) {
        const response = await _axios.get('nursemetadata/getmetadatafornurse', { headers: { 'nurseId': id } })
        if (response.data.resFlag) {
            return new NurseMetaData(response.data.data)
        }
        throw new UsersError('No meta data!')
    },
    async editNurseMetaData(body) {
        const response = await _axios.put('nursemetadata/updatemetadatafornurse', body)
        if (response.data.resFlag) {
            return new NurseMetaData(response.data.data)
        }
        throw new UsersError('No meta data!')
    },
}