import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
export default {
    async createMedicalTeam(body){
        const response = await _axios.post('medicalteam', body)
        if (response.data.resFlag) {
          const userJson = response.data.data
          return userJson
        } else {
          throw new UsersError('Invalid id')
        }
    },
    async assignMemberToMedicalTeam(body){
      const response = await _axios.post('medicalteam/assignmemberusertomedicalteam', body)
      if (response.data.resFlag) {
        const userJson = response.data.data
        return userJson
      } else {
        throw new UsersError('Invalid id')
      }
    },
    async deassignMemberToMedicalTeam(body){
      const response = await _axios.post('medicalteam/deassignmemberuserfrommedicalteam', body)
      if (response.data.resFlag) {
        const userJson = response.data.data
        return userJson
      } else {
        throw new UsersError('Invalid id')
      }
    },
    async editMedicalTeam(body){
      const response = await _axios.put(`medicalteam/updatemedicalteamforpatient`, body)
      if (response.data.resFlag) {
        const userJson = response.data.data
        return userJson
      } else {
        throw new UsersError('Invalid id')
      }
    },
    async deleteMedicalTeam(body){
      const response = await _axios.delete(`medicalteam/deletemedicalteamforpatient`, { headers: { 'patientId': body.patientId , "hospitalId":  body.hospitalId}})
      if (response.data.resFlag) {
        const userJson = response.data.data
        return userJson
      } else {
        throw new UsersError('Invalid id')
      }
    },
    async getMedicalTeamForPatient (patientId) {
      const response = await _axios.get('medicalteam/getmedicalteamsforpatient', { headers: { 'patientId': patientId }} )
      if (response.data.resFlag) {
        return response.data.data
      }
      throw new UsersError('No meta data!')
    },
    async getMedicalTeamForDoctor (doctorId) {
      const response = await _axios.get('medicalteam/getmedicalteamsfordoctor', { headers: { 'doctorId': doctorId }} )
      if (response.data.resFlag) {
        return response.data.data
      }
      throw new UsersError('No meta data!')
    },
    async getMedicalTeamForNurse (nurseId) {
      const response = await _axios.get('medicalteam/getmedicalteamsfornurse', { headers: { 'nurseId': nurseId }} )
      if (response.data.resFlag) {
        return response.data.data
      }
      throw new UsersError('No meta data!')
    },
    async getMedicalTeamForHospitalAdmin (adminId) {
      const response = await _axios.get('medicalteam/getmedicalteamsforhospitaladmin', { headers: { 'hospitalAdminId': adminId }} )
      if (response.data.resFlag) {
        return response.data.data
      }
      throw new UsersError('No meta data!')
    },
}