import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
import DoctorMetaData from "../../models/DoctorMetaData";
export default {
    async getMetaData(id) {
        const response = await _axios.get('doctormetadata/getmetadatafordoctor', { headers: { 'doctorId': id } })
        if (response.data.resFlag) {
            return new DoctorMetaData(response.data.data)
        }
        throw new UsersError('No meta data!')
    },
    async editDoctorMetaData(body) {
        const response = await _axios.put('doctormetadata/updatemetadatafordoctor', body)
        if (response.data.resFlag) {
            return new DoctorMetaData(response.data.data)
        }
        throw new UsersError('No meta data!')
    },
    async assignDoctorToSpetialization(body) {
        const response = await _axios.post('doctormetadata/assigndoctortospetialization', body)
        if (response.data.resFlag) {
            return response.data
        }
        throw new UsersError('No meta data!')
    },
    async deAssignDoctorToSpetialization(body) {
        const response = await _axios.post('doctormetadata/deassigndoctorfromspetialization', body)
        if (response.data.resFlag) {
            return response.data
        }
        throw new UsersError('No meta data!')
    },
}