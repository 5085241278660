import Repository from '../../repositories/RepositoryFactory'

const TreatMentPlanRepository = Repository.get('treatmentplan')

export default {
    namespaced: true,
    state: {
        treamentPlans: null
    },
    mutations: {
        TREATMENT_PLANS_FOR_USER(state, payload) {
            state.treamentPlans = payload
        },
        CREATE_TREATMENT_PLAN_FOR_USER(state, payload) {
            state.treamentPlans.unshift(payload)
            if (state.treamentPlans.length > 1) {
                state.treamentPlans[1].status = 0
            }
        },
        DEASSIGN_DEVICE(state, payload) {
            state.treamentPlans[0].treatmentPlanDevicesList = state.treamentPlans[0].treatmentPlanDevicesList.filter(d => { return d.id !== payload.id })
        },
        ASSIGN_DEVICE(state, payload) {
            state.treamentPlans[0].treatmentPlanDevicesList.unshift(payload)
        }
    },
    actions: {
        async createTreatmentPlan({ commit }, body) {
            commit('CREATE_TREATMENT_PLAN_FOR_USER', await TreatMentPlanRepository.createTreatmentPlan(body))
        },
        async getTreatmentPlansForPatient({ commit }, id) {
            commit('TREATMENT_PLANS_FOR_USER', await TreatMentPlanRepository.getTreatmentPlansForPatient(id))
        },
        async deassignDevice({ commit }, id) {
            commit('DEASSIGN_DEVICE', await TreatMentPlanRepository.deassignDevice(id))
        },
        async assignDevice({ commit }, body) {
            commit('ASSIGN_DEVICE', await TreatMentPlanRepository.assignDevice(body))
        },
    },
    getters: {},
}