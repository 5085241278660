import { VerifiersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'

export default {
    async getVerifiersForDomain(header) {
        const response = await _axios.get('verifier/getverifiersfordomain', { headers: header })
        if (response.data.resFlag) {
            return response.data.data
        }
        throw new VerifiersError('No meta data!')
    },
    async getTimeZones() {
        const response = await _axios.get('verifier/gettimezones')
        if (response.data.resFlag) {
            return response.data.data
        }
        throw new VerifiersError('No meta data!')
    }
}