import authentication from './modules/authentication'
import users from './modules/users'
import countries from './modules/countries'
import medicalInstitutions from './modules/medicalInstitutions'
import hospitals from './modules/hospitals'
import roles from './modules/roles'
import medicalteam from './modules/medicalteam'
import trustedhub from './modules/trustedhub'
import medicaldevice from './modules/medicaldevice'
import patientmetadata from './modules/patientmetadata'
import nosessionhubs from './modules/nosessionhubs'
import verifiers from './modules/verifiers'
import doctormetadata from './modules/doctormetadata'
import nursemetadata from './modules/nursemetadata'
import publiclinks from './modules/publiclinks'
import metrics from "./modules/metrics";
import socket from './modules/socket';
import notifications from './modules/notifications'
import treatmentplan from './modules/treatmentplan'
import appointments from './modules/appointments'
import appointmentrequests from './modules/appointmentrequests'
import googleAPI from './modules/geolocation'

export default {
    authentication,
    users,
    countries,
    medicalInstitutions,
    hospitals,
    roles,
    medicalteam,
    trustedhub,
    medicaldevice,
    patientmetadata,
    nosessionhubs,
    verifiers,
    doctormetadata,
    nursemetadata,
    publiclinks,
    metrics,
    socket,
    notifications,
    treatmentplan,
    googleAPI,
    appointments,
    appointmentrequests
}