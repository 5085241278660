<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  methods: {
		on_resize () {
			this.$store.commit('SET_SCREEN_SIZE', {
        height:	window.innerHeight,
        width: window.innerWidth,
			});
		},
	},
  async mounted () {
    this.$store.commit('SET_SCREEN_SIZE', {
			height:	window.innerHeight,
			width: window.innerWidth,
		});
  }
}
</script>
<style lang="scss">
#app {
  font-family: Catamaran, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #E8E8E8 ;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
