import Repository from '../../repositories/RepositoryFactory'

const MedicalDeviceRepository = Repository.get('medicaldevice')

export default {
    namespaced: true,
    state: {
        bloodPresure: [],
    },
    mutations: {
        LOAD_BLOOD_PRESURE_DATA (state, payload) {
            state.bloodPresure = payload
        }
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async createMedicalDevice ({ commit }, body) {
            const res = await MedicalDeviceRepository.createMedicalDevice(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async editMedicalDevice ({ commit }, body) {
            const res = await MedicalDeviceRepository.editMedicalDevice(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async deleteMedicalDevice ({ commit }, body) {
            const res = await MedicalDeviceRepository.deleteMedicalDevice(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async getMyMedicalDevices({commit}, id) {
            const res = await MedicalDeviceRepository.getMyMedicalDevices(id)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async getDeviceById({commit}, id) {
            const res =  await MedicalDeviceRepository.getDeviceById(id);
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async getmedicaldevicebydeviceidentifier({commit}, id) {
            commit('LOAD_BLOOD_PRESURE_DATA', await MedicalDeviceRepository.getmedicaldevicebydeviceidentifier(id));
            // return res
        },
        // eslint-disable-next-line no-unused-vars
        async getMedicalDeviceTemplate({commit}) {
            const res = await MedicalDeviceRepository.getMedicalDeviceTemplate()
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async editMedicalDeviceTemplate ({ commit }, body) {
            const res = await MedicalDeviceRepository.editMedicalDeviceTemplate(body)
            return res
        },
    },
    getters: {},
}