module.exports = class TrustedHubs {
    constructor(object) {
        this.assignedMedicalDevicesList = object.assignedMedicalDevicesList;
        this.assignedToUserId = object.assignedToUserId;
        this.hospitalId = object.hospitalId;
        this.hubIdentifier = object.hubIdentifier;
        this.hubMAC = object.hubMAC;
        this.hubName = object.hubName;
        this.id = object.id;
        this.status = object.status;
    }
};