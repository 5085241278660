export default {
    namespaced: true,
    state: {
        scaleAndTempUnit: 'metric',
        glucoseAndCholesterolUnit: 'mmol/L',
    },
    mutations: {
        SET_METRIC(state, payload) {
            state.scaleAndTempUnit = payload.scaleAndTempUnit
            state.glucoseAndCholesterolUnit = payload.glucoseAndCholesterolUnit
        },
    },
    actions: {
        async setMetric({commit}, units) {
            commit('SET_METRIC', units)
        },
    },
    getters: {
        getUnits(state) {
            var units = {
                scaleAndTempUnit: state.scaleAndTempUnit,
                glucoseAndCholesterolUnit: state.glucoseAndCholesterolUnit
            }
            return units
        },
    },
}