export default class ExtendableError extends Error {
    constructor (message, ...params) {
      super(message, ...params)
      this.name = this.constructor.name
      if (typeof Error.captureStackTrace === 'function') {
        Error.captureStackTrace(this, this.constructor)
      } else {
        this.stack = (new Error(message)).stack
      }
    }
  }