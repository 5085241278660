import Repository from '../../repositories/RepositoryFactory'

const MedicalTeamRepository = Repository.get('medicalteam')

export default {
    namespaced: true,
    state: {
        medicalTeam: [],
    },
    mutations: {
        LOAD_MEDICAL_TEAM (state, payload) {
            state.medicalTeam = payload.medicalTeamsData
        }
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async createMedicalTeam({ commit }, body) {
            const res = await MedicalTeamRepository.createMedicalTeam(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async assignMemberToMedicalTeam({ commit }, body) {
            const res = await MedicalTeamRepository.assignMemberToMedicalTeam(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async deassignMemberToMedicalTeam({ commit }, body) {
            const res = await MedicalTeamRepository.deassignMemberToMedicalTeam(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async editMedicalTeam({ commit }, body) {
            const res = await MedicalTeamRepository.editMedicalTeam(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async deleteMedicalTeam({ commit }, body) {
            const res = await MedicalTeamRepository.deleteMedicalTeam(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async getMedicalTeamForPatient({ commit }, id) {
            const res = await MedicalTeamRepository.getMedicalTeamForPatient(id)
            return res
        },
        async getMedicalTeamForDoctor(_, id) {
            const res = await MedicalTeamRepository.getMedicalTeamForDoctor(id)
            return res
        },
        async getMedicalTeamForNurse({ commit }, id) {
            const res = await MedicalTeamRepository.getMedicalTeamForNurse(id)
            commit('LOAD_MEDICAL_TEAM', res)
            return res
        },
        async getMedicalTeamForHospitalAdmin({ commit }, id) {
            const res = await MedicalTeamRepository.getMedicalTeamForHospitalAdmin(id)
            commit('LOAD_MEDICAL_TEAM', res)
            return res
        },
    },
    getters: {},
}