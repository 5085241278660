import Vue from 'vue'
import VueRouter from 'vue-router'
import { requireAuth } from '@/router/authentication.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: { requireAuth: true },
    component: () => import('@/views/Index'),
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () => import('@/views/Client/components/NewDesign/NewComponent'),
      },
      {
        path: '/patientdetails/:id(\\d+)',
        name: 'Patient Details',
        component: () => import('@/views/Client/components/NewDesign/PatientDetails'),
      },
      {
        path: '/devicemeasurements/:id(\\d+)', // Expr. matches only numbers!',
        name: 'Device Measurements',
        component: () => import('@/views/Client/partials/DeviceMeasurements'),
      },
      {
        path: '/medicalorganizations',
        name: 'Medical Organizations',
        component: () => import('@/views/Admin/MedicalInstitutions'),
      },
      {
        path: '/admins',
        name: 'Admins',
        component: () => import('@/views/Admin/Admins'),
      },
      {
        path: '/hospitals',
        name: 'Hospitals',
        component: () => import('@/views/Admin/Hospitals'),
      },
      {
        path: '/publiclinks',
        name: 'Public Links',
        component: () => import('@/views/dialogs/PublicLinks'),
      },
      {
        path: '/patients',
        name: 'Patients',
        component: () => import('@/views/Client/partials/Patients'),
      },
      {
        path: '/medicalteam',
        name: 'Medical Team',
        component: () => import('@/views/Client/partials/MedicalTeam'),
      },
      {
        path: '/medicalstaff',
        name: 'Medical Staff',
        component: () => import('@/views/Client/partials/MedicalStaff'),
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    name: 'Login',
    component: () => import('@/views/authentication/Login'),
    children: [
      {
        path: '/auth/login',
        component: () => import('@/views/authentication/Login'),
      },
    ],
  },
  {
    path: '/api/user/confirmvalidation',
    name: 'Confirm Validation',
    component: () => import('@/views/authentication/ResetPassword')
  },
  {
    path: '/room6/:id(\\d+)/:userId(\\d+)/:appointmentId(\\d+)',
    meta: { reqviewsreAuth: true },
    name: 'Room6',
    component: () => import('@/views/no-authentication/room6')
  },
  {
    path: '/auth/forgetpassword',
    name: 'Forget Password',
    component: () => import('@/views/authentication/ForgetPassword'),
  },
  {
    path: '/measurements',
    name: 'HTML Rendering',
    component: () => import('@/views/Client/partials/no-auth/RenderData'),
  },
  {
    path: '/measurements/:hubIdentifier',
    name: 'Patient Report',
    component: () => import('@/views/Client/partials/no-auth/RenderDataForPatient'),
  },
  {
    path: '/:pathMatch(.*)*', redirect: '/'
  },
]
const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes: routes,
})

router.beforeEach(requireAuth)
export default router
