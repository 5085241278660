import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
import Notification from '../../models/Notifications'
import Message from '../../models/Message'
export default {
    async sendMessage(body) {
        const response = await _axios.post('pushnotifications/sendnotificationtouser', body)
        if (response.data.resFlag) {
            const n = new Message(response.data.data)
            return n;
        }
        throw new UsersError('No meta data!')
    },
    async sendMessageToMedicalTeam(body) {
        const response = await _axios.post('pushnotifications/sendnotificationtomedicalteam', body)
        if (response.data.resFlag) {
            return response.data
        }
        throw new UsersError('No meta data!')
    },
    async markAsRead(notificationId) {
        const response = await _axios.get('pushnotifications/markasread', { headers: { 'notificationId': notificationId } })
        if (response.data.resFlag) {
            return response.data
        }
        throw new UsersError('No meta data!')
    },
    async markAllAsReadWithUser(id) {
        const response = await _axios.get('pushnotifications/markallnotificationsasreadwithuserfornotificationtype', { headers: { 'userId': id, 'notificationTypeId': 1 } })
        if (response.data.resFlag) {
            return response.data
        }
        throw new UsersError('No meta data!')
    },
    async ALL_NOTIFICATIONS(filter) {
        const response = await _axios.get('pushnotifications/getmypushnotifications', { headers: { 'filterCase': filter, } })
        if (response.data.resFlag) {
            const notifications = response.data?.data?.myNotifications.map(n => {
                const notification = new Notification(n);
                return notification;
            });
            return notifications;
            // return response.data?.data?.myNotifications
        }
        throw new UsersError('No meta data!')
    },
    async getMessagesWithUser(id) {
        const response = await _axios.get('pushnotifications/getmymessageswithuser', { headers: { "userId": id } })
        if (response.data.resFlag) {
            const notifications = response.data?.data?.myNotifications.map(n => {
                const notification = new Notification(n);
                return notification;
            });
            return notifications;
            // return response.data?.data?.myNotifications
        }
        throw new UsersError('No meta data!')
    },
}