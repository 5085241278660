const DoctorMetaData = require('./DoctorMetaData')
const NurseMetaData = require('./NurseMetaData')
const PatientMetadata = require('./PatientMetaData')
module.exports = class UserObject {
    constructor(object) {
        this.address = object.address;
        this.addressLatitude = object.addressLatitude;
        this.addressLongitude = object.addressLongitude;
        this.birthdate = object.birthdate;
        this.birthdateAsString = object.birthdateAsString;
        this.birthdateWithTime = object.birthdateWithTime;
        this.birthdateWithoutTime = object.birthdateWithoutTime;
        this.city = object.city;
        this.country = object.country;
        this.countryName = object.countryName;
        this.doctorMetadataReadDto = object.doctorMetadataReadDto !== null ? new DoctorMetaData(object.doctorMetadataReadDto) : null;
        this.email = object.email;
        this.firstName = object.firstName
        this.gender = object.gender;
        this.medicalTeamsData = object.medicalTeamsData;
        this.hospitalAssignedData = object.hospitalAssignedData;
        this.id = object.id;
        this.lastName = object.lastName;
        this.nurseMetadata = object.nurseMetadata !== null ? new NurseMetaData(object.nurseMetadata) : null;
        this.patientMetadata = object.patientMetadata !== null ? new PatientMetadata(object.patientMetadata) : null;
        this.phone = object.phone;
        this.roleData = object.roleData;
        this.roleId = object.roleId;
        this.state = object.state;
        this.status = object.status;
        this.userTimezone = object.userTimezone;
        this.username = object.username;
        this.zip = object.zip;
        this.userSettingsJson = object.userSettingsJson;
    }
};