import Repository from '../../repositories/RepositoryFactory'
const VerifiersRepository = Repository.get('verifiers')

export default {
    namespaced: true,
    state: {
        verifiers: null,
        timeZones: []
    },
    mutations: {
        GET_VERIFIERS(state, payload) {
            state.verifiers = payload
        },
        GET_TIME_ZONES(state, payload) {
            state.timeZones = payload
        }
    },
    actions: {
        async getVerifiersForDomain({ commit }, header) {
            commit('GET_VERIFIERS', await VerifiersRepository.getVerifiersForDomain(header))
        },
        async getTimeZones({ commit }) {
            commit('GET_TIME_ZONES', await VerifiersRepository.getTimeZones())
        },
    },
    getters: {},
}