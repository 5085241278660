import _axios from '@/plugins/axios'

export default {
    async setWebSocketToken () {
        const response = await _axios.post('/user/setwebsockettoken')

        if (response.data.resFlag) {
            return true
        } else {
            // console.log('Error')
        }
    },
}