import GoogleAPIRepository from '../../repositories/v1/GoogleAPIRepository'
export default {
    namespaced: true,
    state: {
        geolocation: null
    },
    mutations: {
        GEO_LOCATION(state, payload) {
            state.geolocation = payload
        },
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async getGeoLocation({ commit }, city) {
            const resgeo_location = await GoogleAPIRepository.getLocationDetails(city)
            return resgeo_location.results[0]
        },
        // eslint-disable-next-line no-unused-vars
        async getTimeZone ({ commit }, object) {
            const res = await GoogleAPIRepository.getTimeZone(object)
            return res
        }
    },
    getters: {},
}