import Repository from '../../repositories/RepositoryFactory'

const NoSessionHubsRepository = Repository.get('nosessionhubs')

export default {
    namespaced: true,
    state: {
        hubs: null
    },
    mutations: {
        GET_HUBS_DATA(state, payload) {
            state.hubs = payload
        },
    },
    actions: {
        async getHubsData({ commit }, data) {
            commit('GET_HUBS_DATA', await NoSessionHubsRepository.getHubsData(data))
        },
    },
    getters: {},
}