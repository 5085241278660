import Repository from '../../repositories/RepositoryFactory'
import ws from '../../../lib/websocket';

const SocketRepository = Repository.get('websocket')

export default {
	namespaced: true,
	state: {
		allNotifications: [],
		onlineConsoles: [],
		wsToken: null,
	},
	getters: {

	},
	mutations: {
		UPDATE_ONLINE_CONSOLES: (state, payload) => {
			state.onlineConsoles = payload;
		},
		SET_TOKEN_WEBSOCKET: (state, payload) => {
			state.onlineConsoles = payload;
		},
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		async setConnection ({ commit }) {
			ws();
		},
		async setWebSocketToken ({ commit }) {
			commit('SET_TOKEN_WEBSOCKET', await SocketRepository.setWebSocketToken())
		},
	},
};
