/* eslint-disable no-unused-vars */
import Repository from '../../repositories/RepositoryFactory'

const PublicLinksRepository = Repository.get('publiclinks')

export default {
    namespaced: true,
    state: {
        publicLinks: null,
        createdLink: null,
        measurements: null
    },
    mutations: {
        GET_PUBLIC_LINKS(state, payload) {
            state.publicLinks = payload.data
        },
        GET_CREATED_PUBLIC_LINK(state, payload) {
            state.createdLink = payload.data
        },
        GET_MEASUREMENTS_FROM_LINK(state, payload) {
            state.measurements = payload.data
        }
    },
    actions: {
        async getPublicLinks({ commit }) {
            commit('GET_PUBLIC_LINKS', await PublicLinksRepository.getPublicLinks())
        },
        async createPublicLink({ commit }, body) {
            commit('GET_CREATED_PUBLIC_LINK', await PublicLinksRepository.createPublicLink(body))
        },
        async deletePublicLink({ commit }, body) {
            const res = await PublicLinksRepository.deletePublicLink(body)
        },
        async shareLinkWithEmail(body) {
            const res = await PublicLinksRepository.shareLinkWithEmail(body)
            return res
        },
        async getMeasurementsFromLink({ commit }, linkId) {
            commit('GET_MEASUREMENTS_FROM_LINK', await PublicLinksRepository.getMeasurementsFromLink(linkId))
        },
    },
    getters: {},
}