import Repository from '../../repositories/RepositoryFactory'
import Message from "../../models/Message";
import authentication from './authentication'
const NotificationsRepository = Repository.get('notifications')
export default {
    namespaced: true,
    state: {
        allNotifications: [],
        abnormalMeasurements: [],
        reminders: [],
        missMeasurements: [],
        dailyGoals: [],
        chatNotifications: [],
        notificationsWithUser: [],
        appointmentRequests: [],
        msgStatus: null
    },
    mutations: {
        ALL_NOTIFICATIONS(state, payload) {
            state.allNotifications = payload.filter(p => { return p.FromUserId !== authentication.state.userData.id })
            // state.allNotifications = payload.filter(p => { return p.DeviceType !== null }) // We should generate another 'NotificationTypeId' for Chat Messages, to differentiate them from notifications!
            state.chatNotifications = payload.filter(p => { return p.DeviceType === null && p.FromUserId !== authentication.state.userData.id && p.NotificationTypeId === 1 }) // We should generate another 'NotificationTypeId' for Chat Messages, to differentiate them from notifications!
            state.abnormalMeasurements = payload.filter(p => { return p.NotificationTypeId === 3 })
            state.reminders = payload.filter(p => { return p.NotificationTypeId === 4 })
            state.missMeasurements = payload.filter(p => { return p.NotificationTypeId === 5 })
            state.dailyGoals = payload.filter(p => { return p.NotificationTypeId === 6 })
            state.appointmentRequests = payload.filter(p => { return p.NotificationTypeId === 7 && p.FromUserId !== authentication.state.userData.id })
        },
        CHAT_MESSAGES(state, payload) {
            state.notificationsWithUser = payload.filter(p => { return p.DeviceType === null })
            state.notificationsWithUser.reverse();
        },
        ALL_NOTIFICATIONS_FROM_WS(state, payload) {
            if (payload.DeviceType !== null) {
                state.allNotifications.unshift(payload)
                switch (payload.NotificationTypeId) {
                    case 3:
                        state.abnormalMeasurements.unshift(payload)
                        break;
                    case 4:
                        state.reminders.unshift(payload)
                        break;
                    case 5:
                        state.missMeasurements.unshift(payload)
                        break;
                    case 6:
                        state.dailyGoals.unshift(payload)
                        break;
                }
            } else {
                state.allNotifications.unshift(payload)
                if (payload.FromUserId !== undefined) {
                    payload.IsRead = false
                }
                if (payload.NotificationTypeId === 7) {
                    state.appointmentRequests.unshift(payload)
                }

                if (payload.NotificationTypeId === 1) {
                    state.notificationsWithUser.push(payload)
                    state.chatNotifications.push(payload)
                }
            }
        },
        ABNORMAL_MEASUREMENTS_FROM_WS(state, payload) {
            state.abnormalMeasurements.unshift(payload)
        },
        REMINDER_MEASUREMENTS_FROM_WS(state, payload) {
            state.reminders.unshift(payload)
        },
        MISS_MEASUREMENTS_FROM_WS(state, payload) {
            state.missMeasurements.unshift(payload)
        },
        DAILY_MEASUREMENTS_FROM_WS(state, payload) {
            state.dailyGoals.unshift(payload)
        },
        // eslint-disable-next-line no-unused-vars
        MARK_AS_READ_LAST_ONE(state, payload) {
            state.notificationsWithUser[state.notificationsWithUser.length - 1].IsRead = true
        },
        CHANGE_MESSAGE_STATUS(state, payload) {
            payload.notificationMsg = payload.messageToSend
            state.notificationsWithUser.push(new Message(payload))
            state.msgStatus = 'sent'
        },
        SEND_MESSAGE(state) {
            state.msgStatus = 'delivered'
        }
    },
    actions: {
        async sendMessage({ commit }, body) {
            commit('SEND_MESSAGE', await NotificationsRepository.sendMessage(body))
        },
        async changeMessageStatus({ commit }, body) {
            commit('CHANGE_MESSAGE_STATUS', body)
        },
        // eslint-disable-next-line no-unused-vars
        async sendMessageToMedicalTeam({ commit }, body) {
            const res = await NotificationsRepository.sendMessageToMedicalTeam(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async markAsRead({ commit }, notificationId) {
            commit('MARK_AS_READ_LAST_ONE', await NotificationsRepository.markAsRead(notificationId))
        },
        // eslint-disable-next-line no-unused-vars
        async markNotificationAsRead({ commit }, notificationId) {
            await NotificationsRepository.markAsRead(notificationId)
        },
        // eslint-disable-next-line no-unused-vars
        async markAllAsReadWithUser({ commit }, id) {
            await NotificationsRepository.markAllAsReadWithUser(id)
        },
        async ALL_NOTIFICATIONS({ commit }, filter) {
            commit('ALL_NOTIFICATIONS', await NotificationsRepository.ALL_NOTIFICATIONS(filter))
        },
        async getMessagesWithUser({ commit }, id) {
            commit('CHAT_MESSAGES', await NotificationsRepository.getMessagesWithUser(id))
        },
    },
}