import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
export default {
    async getUsers () {
        var response = [] 
        await _axios.get('user').then((res) => {
          response = res
        }).catch((err) => {
          if( err.response ){
            response = err.response // => the response payload 
          }
        })
        return response.data
    },
    async getUserById (id) {
      const response = await _axios.get(`/user/${id}`)
      if (response.data.resFlag) {
        const userJson = response.data.data
        return userJson
      } else {
        throw new UsersError('Invalid id')
      }
    },
    async createUser(body){
      var response = [] 
      await _axios.post('user', body).then((res) => {
          response = res
        }).catch((err) => {
          if( err.response ){
            response = err.response // => the response payload 
          }
        })
        return response.data
    },
    async updateUser(body){
      const response = await _axios.put(`user/${body.id}`, body)
      if (response.data.resFlag) {
        const userJson = response.data
        return userJson
      } else {
        throw new UsersError('Invalid id')
      }
    },
    async deleteUser(body){
      const response = await _axios.delete(`user/${body.id}`)
      if (response.data.resFlag) {
        const userJson = response.data.data
        return userJson
      } else {
        throw new UsersError('Invalid id')
      }
    },
    async changePassword(body){
      
      const response = await _axios.post('user/changemypassword', body)
      if (response.data.resFlag) {
        const userJson = response.data
        return userJson
      } else {
        throw new UsersError('Password could not be changed!')
      }
    },
    async changePasswordWithValidationToken(body){
      
      const response = await _axios.post('user/changemypasswordwithvalidationtoken', body)
      if (response.data.resFlag) {
        const userJson = response.data
        return userJson
      } else {
        throw new UsersError('Password could not be changed!')
      }
    },
    async sendValidationLinkByEmail(body){
      const response = await _axios.post('user/sendvalidationlinkbyemail', body)
      if (response.data.resFlag) {
        const userJson = response.data
        return userJson
      } else {
        throw new UsersError('Validation link could not be sent!')
      }
    },
    async getmyuserprofilephoto () {
      const response = await _axios.get('/user/getmyprofileimage')
      if (response.data.resFlag) {
        const userJson = response.data
        return userJson
      } else {
        throw new UsersError('Could not get image!')
      }
    },
    async uploadmyuserprofilephoto (formData) {
      const response = await _axios.post('/user/uploadmyprofileimage', formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      if (response.data.resFlag) {
        const userJson = response.data
        return userJson
      } else {
        throw new UsersError('Could not upload image!')
      }
    },
}
