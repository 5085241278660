import Repository from '../../repositories/RepositoryFactory'

const AppointmentsRepository = Repository.get('appointments')

export default {
    namespaced: true,
    state: {
        appointments: null,
        notes: null
    },
    mutations: {
        GET_APPOINTMENTS(state, payload) {
            state.appointments = payload.data
        },
        CREATE_APPOINTMENTS(state, payload) {
            payload.data.appointmentDateTime = payload.data.appointmentDateTime.split('Z')[0]
            state.appointments.unshift(payload.data)
        },
        ADD_NOTE() {

        },
        GET_NOTES(state, payload) {
            state.notes = payload.data
        },
    },
    actions: {
        async getAppointments({ commit }) {
            commit('GET_APPOINTMENTS', await AppointmentsRepository.getAppointments())
        },
        // eslint-disable-next-line no-unused-vars
        async createAppointment({ commit }, body) {
            commit('CREATE_APPOINTMENTS', await AppointmentsRepository.createAppointment(body))
        },
        async addAppointmentNote({ commit }, body) {
            commit('ADD_NOTE', await AppointmentsRepository.addAppointmentNote(body))
        },
        async getAllNotes({ commit }) {
            commit('GET_NOTES', await AppointmentsRepository.getAllNotes())
        },
    },
    getters: {},
}