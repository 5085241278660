/* eslint-disable no-unused-vars */
import Repository from '../../repositories/RepositoryFactory'

const libphonenumber = require('libphonenumber-js');
const MedicalInstitutionsRepository = Repository.get('medicalInstitutions')

export default {
    namespaced: true,
    state: {
        medicalInstitutions: null,
        detailedMedicalInstitution: null
    },
    mutations: {
        GET_MEDICAL_INSTITUTIONS (state, payload) {
            payload.data.forEach(c => {
                if (c.contactPhone.includes('+')) {
                    c.contactPhone = libphonenumber.parsePhoneNumberFromString(c.contactPhone).formatInternational();
                }
            })
            state.medicalInstitutions = payload.data
        },
        GET_MEDICAL_INSTITUTIONS_BY_ID (state, payload) {
            state.detailedMedicalInstitution = payload.medicalInstitutionData
        }
    },
    actions: {
        async getMedicalInstitutions ({ commit }) {
            commit('GET_MEDICAL_INSTITUTIONS', await MedicalInstitutionsRepository.getMedicalInstitutions())
        },
        async getMedicalInstitutionById ({ commit }, id) {
            commit('GET_MEDICAL_INSTITUTIONS_BY_ID', await MedicalInstitutionsRepository.getMedicalInstitutionById(id))
        },
        async createMedicalInstitution ({ commit }, body) {
            const res = await MedicalInstitutionsRepository.createMedicalInstitution(body)
            return res
        },
        async editMedicalInstitution ({ commit }, body) {
            const res = await MedicalInstitutionsRepository.editMedicalInstitution(body)
            return res
        },
        async deleteMedicalInstitution ({ commit }, id) {
            const res = await MedicalInstitutionsRepository.deleteMedicalInstitution(id)
            return res
        },
    },
    getters: {},
}