import { UsersError } from '@/lib/Errors'
import TrustedHubs from '../../models/TrustedHubs'
import axios from "axios";
export default {
    async getHubsData(data) {
        let config = {
            baseURL: process.env.VUE_APP_NO_SESSION,
            timeout: 60 * 1000, // Timeout
            withCredentials: false, // Check cross-site Access-Control
            //Accept: '*/*',
        };
        const api = axios.create(config);
        const response = await api.get('medicaldevicesdata/getmeasurementsforpatientbyidentifier', { params: { patientIdentifier: data.id, filterCase: data.filter } })
        if (response.data.resFlag) {
            let object = response.data?.data[0] || []
            let newObject = new TrustedHubs(object)
            return newObject
        }
        throw new UsersError('No meta data!')
    },
}