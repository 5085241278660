import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
export default {
    async getMedicalInstitutions () {
        const response = await _axios.get('medicalinstitution')
        if (response.data.resFlag) {
          return response.data
        }
        throw new UsersError('No meta data!')
    },
    async getMedicalInstitutionById (id) {
      const response = await _axios.get(`/medicalinstitution/${id}`)
      if (response.data.resFlag) {
        const userJson = response.data.data
        return userJson
      } else {
        throw new UsersError('Invalid id')
      }
    },
    async createMedicalInstitution (body) {
        const response = await _axios.post('medicalinstitution', body)
          
        if (response.data.resFlag) {
          return response.data
        }
        throw new UsersError('No meta data!')
    },
    async editMedicalInstitution(body){
        const response = await _axios.put(`medicalinstitution/${body.id}`, body)
        if (response.data.resFlag) {
          const userJson = response.data.data
          return userJson
        } else {
          throw new UsersError('Invalid id')
        }
    },
    async deleteMedicalInstitution(id){
      const response = await _axios.delete(`medicalinstitution/${id}`)
      if (response.data.resFlag) {
        const userJson = response.data.data
        return userJson
      } else {
        throw new UsersError('Invalid id')
      }
    },
}