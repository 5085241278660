import Repository from '../../repositories/RepositoryFactory'

const PatientMetaDataRepository = Repository.get('patientmetadata')

export default {
    namespaced: true,
    state: {
        metaData: null,
        editedData: null
    },
    mutations: {
        GET_META_DATA(state, payload) {
            state.metaData = payload
        },
        EDIT_META_DATA(state, payload) {
            state.editedData = payload
        },
    },
    actions: {
        async getMetaData({ commit }, id) {
            commit('GET_META_DATA', await PatientMetaDataRepository.getMetaData(id))
        },
        async editPatientMetaData({ commit }, body) {
            commit('EDIT_META_DATA', await PatientMetaDataRepository.editPatientMetaData(body))
        },
    },
    getters: {},
}