import Repository from '../../repositories/RepositoryFactory'
const UsersRepository = Repository.get('users')

export default {
    namespaced: true,
    state: {
        users: null,
        detailedUser: null
    },
    mutations: {
        GET_USERS (state, payload) {
            var removeDuplicates = payload.data?.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
            state.users = removeDuplicates
        },
        GET_USER_BY_ID (state, payload) {
            state.detailedUser = payload
        },
    },
    actions: {
        async getUsers ({ commit }) {
            commit('GET_USERS', await UsersRepository.getUsers())
        },
        async getUserById ({ commit }, id) {
            commit('GET_USER_BY_ID', await UsersRepository.getUserById(id))
        },
        // eslint-disable-next-line no-unused-vars
        async createUser ({ commit }, body) {
            const res = await UsersRepository.createUser(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async updateUser ({ commit }, body) {
            const res = await UsersRepository.updateUser(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async deleteUser ({ commit }, body) {
            const res = await UsersRepository.deleteUser(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async changePassword ({ commit }, body) {
            const res = await UsersRepository.changePassword(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async changePasswordWithValidationToken ({ commit }, body) {
            const res = await UsersRepository.changePasswordWithValidationToken(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async sendValidationLinkByEmail ({ commit }, body) {
            const res = await UsersRepository.sendValidationLinkByEmail(body)
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async getmyuserprofilephoto ({ commit }) {
            const res = await UsersRepository.getmyuserprofilephoto()
            return res
        },
        // eslint-disable-next-line no-unused-vars
        async uploadmyuserprofilephoto ({ commit }, formData) {
            const res = await UsersRepository.uploadmyuserprofilephoto(formData)
            return res
          },
    },
    getters: {},
}