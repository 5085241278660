import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
export default {
  async getPublicLinks() {
    const response = await _axios.get('publiclink')
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No public links!')
  },
  async createPublicLink(body) {
    const response = await _axios.post('publiclink', body)
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No public links!')
  },
  async deletePublicLink(body) {
    const response = await _axios.delete(`publiclink/${body.id}`)
    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new UsersError('Invalid id')
    }
  },
  async shareLinkWithEmail(body) {
    const response = await _axios.post('publiclink/sharelinkwithemail', body)
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No public links!')
  },
  async getMeasurementsFromLink(linkId) {
    const response = await _axios.get('publiclink/getmeasurementsfromlink/',{ params: { linkId: linkId } } )
    if (response.data.resFlag) {
      return response.data
    }
    throw new UsersError('No public links!')
  },
}