import { UsersError } from '@/lib/Errors'
import _axios from '@/plugins/axios'
export default {
    async createTrustedHub(body){
      var response = [] 
      await _axios.post('trustedhub', body).then((res) => {
        response = res
      }).catch((err) => {
        if( err.response ){
          response = err.response // => the response payload 
        }
      })
      return response.data
    },
    async deleteTrustedHub(body){
        const response = await _axios.delete(`trustedhub/deletetrustedhubforpatient`, { headers: { 'patientId': body.patientId , "hubIdentifier":  body.hubIdentifier}})
        if (response.data.resFlag) {
          const userJson = response.data.data
          return userJson
        } else {
          throw new UsersError('Invalid id')
        }
      },
    async editTrustedHub(body){
      var response = [] 
        await _axios.put(`trustedhub/updatetrustedhubforpatient`, body).then((res) => {
          response = res
        }).catch((err) => {
          if( err.response ){
            response = err.response // => the response payload 
          }
        })
        return response.data
    },
}