/* eslint-disable no-unused-vars */
import store from '../data/store';
import NotificationsWS from '../data/models/NotificationsWS'

import ReconnectingWebSocket from 'reconnecting-websocket';

let ws;

function isOpen() {
	return ws && ws.readyState === WebSocket.OPEN;
}

function send(messageStr) {
	if (ws && isOpen()) {
		ws.send(messageStr);
	} else {
		// console.log('Tried to send message on closed WebSocket: ' + messageStr);
	}
}

export default function connectWebSocket() {
	const url = `wss://medicalsocket.bridge-techs.com/`;

	ws = new ReconnectingWebSocket(url, null, { debug: false, reconnectInterval: 3000 });

	ws.onopen = () => {
		const message = {
			MessageType: 'initWeb',
			Payload: {
				SessionToken: store.state.authentication.sessionToken,
				UserId: store.state.authentication.userData.id
			}
		};
		send(JSON.stringify(message));
	};

	ws.onmessage = (message) => {
		try {
			var incommingData = JSON.parse(message.data)
			store.commit('notifications/ALL_NOTIFICATIONS_FROM_WS', new NotificationsWS(incommingData.PayloadData))
		} catch {
			// Catch
		}
	};
	ws.onerror = (evt) => {
	};

	ws.onclose = (message) => {
	};

	store.subscribe((mutation, state) => {
		switch (mutation.type) {
			case 'authentication/LOGOUT':
				ws.close();
				break;
		}
	})
}