import Repository from '../../repositories/RepositoryFactory'

const NurseMetaDataRepository = Repository.get('nursemetadata')

export default {
    namespaced: true,
    state: {
        nursemetadata: null,
        editedData: null
    },
    mutations: {
        GET_META_DATA(state, payload) {
            state.nursemetadata = payload
        },
        EDIT_META_DATA(state, payload) {
            state.editedData = payload
        },
    },
    actions: {
        async getMetaData({ commit }, body) {
            commit('GET_META_DATA', await NurseMetaDataRepository.getMetaData(body))
        },
        async editNurseMetaData({ commit }, body) {
            commit('EDIT_META_DATA', await NurseMetaDataRepository.editNurseMetaData(body))
        },
    },
    getters: {},
}