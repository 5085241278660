export default {
    async getLocationDetails (city) {
        let geo_data = null
        await fetch('https://maps.googleapis.com/maps/api/geocode/json?key=' + `${process.env.VUE_APP_GOOGLEMAPAPIKEY}` + '&address=' + city)
            .then(function(response) {
                return response.json()
            }).then(function(data) {
                if (data.status === 'OK') {
                    geo_data = data
                }
            })
        return geo_data
    },
    async getTimeZone (object) {
        let time_zone = null
        await fetch('https://api.timezonedb.com/v2.1/get-time-zone?key=B1BW364LNJZR&format=json&by=position&lat='+ object.lat + '&lng=' + object.lng)
            .then(function(response) {
                return response.json()
            }).then(function(data) {
                if (data.status === 'OK') {
                    time_zone = data
                }
            })
        return time_zone
    }
}