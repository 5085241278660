import AuthenticationRepository from './v1/AuthenticationRepository'
import UsersRepository from './v1/UsersRepository'
import CountriesRepository from './v1/CountriesRepository'
import MedicalInstitutionsRepository from './v1/MedicalInstitutionsRepository'
import HospitalsRepository from './v1/HospitalsRepository'
import RolesRepository from './v1/RolesRepository'
import MedicalTeamRepository from './v1/MedicalTeamRepository'
import TrustedHubRepository from './v1/TrustedHubRepository'
import MedicalDeviceRepository from './v1/MedicalDeviceRepository'
import PatientMetaDataRepository from './v1/PatientMetaDataRepository'
import NoSessionHubsRepository from './v1/NoSessionHubsRepository'
import VerifiersRepository from './v1/VerifiersRepository'
import DoctorMetaDataRepository from './v1/DoctorMetaDataRepository'
import NurseMetaDataRepository from './v1/NurseMetaDataRepository'
import PublicLinksRepository from './v1/PublicLinksRepository'
import SocketRepository from './v1/SocketRepository'
import NotificationsRepository from './v1/NotificationsRepository'
import TreatMentPlanRepository from './v1/TreatMentPlanRepository'
import GoogleAPIReposotiry from './v1/GoogleAPIRepository'
import AppointmentsRepository from './v1/AppointmentsRepository'
import AppointmentRequestsRepository from './v1/AppointmentRequestsRepository'
const repositories = {
  authentication: AuthenticationRepository,
  users: UsersRepository,
  countries: CountriesRepository,
  medicalInstitutions: MedicalInstitutionsRepository,
  hospitals: HospitalsRepository,
  roles: RolesRepository,
  medicalteam: MedicalTeamRepository,
  trustedhub: TrustedHubRepository,
  medicaldevice: MedicalDeviceRepository,
  patientmetadata: PatientMetaDataRepository,
  nosessionhubs: NoSessionHubsRepository,
  verifiers: VerifiersRepository,
  doctormetadata: DoctorMetaDataRepository,
  nursemetadata: NurseMetaDataRepository,
  publiclinks: PublicLinksRepository,
  websocket: SocketRepository,
  notifications: NotificationsRepository,
  treatmentplan: TreatMentPlanRepository,
  googleAPI: GoogleAPIReposotiry,
  appointments: AppointmentsRepository,
  appointmentrequests: AppointmentRequestsRepository
}
export default {
  get: (name) => {
    if (repositories[name]) {
      return repositories[name]
    } else {
      throw new Error('Invalid Repository Type')
    }
  },
}